<template>
    <div class="add-link">
        <input
            v-model="anchorValue"
            class="add-link__input form-control"
            type="text"
            placeholder="Начните вводить текст якоря"
            @input="isShowList = true"
        />

        <transition name="slide">
            <div
                v-if="isShowList"
            >
                <div
                    v-if="anchorValue !== '' && anchorResult.length > 0"
                    class="add-link__block"
                >
                    <button
                        v-for="(item, index) in anchorResult"
                        :key="`anchor-list-${index}`"
                        @click="addLink(item.id)"
                        class="v-btn add-link__item"
                    >
                        <span class="add-link__item-title">
                            {{ item.label }}
                        </span>
                    </button>
                </div>

                <div
                    v-else-if="anchorResult.length === 0"
                    class="add-link__block"
                >
                    Ничего не найдено...
                </div>
            </div>
        </transition>

<!--        <button-->
<!--            v-if="anchorValue !== anchorHash.label"-->
<!--            @click="addLink()"-->
<!--            class="v-btn v-btn&#45;&#45;gold-bordered add-link__button"-->
<!--        >-->
<!--            Вставить ссылку на якорь-->
<!--        </button>-->
    </div>
</template>

<script>
    export default {
        name: 'AddAnchorLinkModal',
        props: {
            anchorHash: {
                type: String
            },

            anchorList: {
                type: Array
            },

            anchorFeedback: {
                type: Function
            }
        },
        data() {
            return {
                anchorValue: null,
                isShowList: false
            }
        },
        computed: {
            anchorResult() {
                return this.anchorList.filter((anchor) => {
                    return anchor.label.toLowerCase()
                        .includes(this.anchorValue.toLowerCase())
                })
            }
        },
        methods: {
            addLink(id) {
                this.anchorFeedback(id)
                this.$modal.hide('add-anchor-link-modal')
            }
        },
        mounted() {
            if (this.anchorHash) {
                 this.anchorList.forEach((anchor) => {
                    if (anchor.id === this.anchorHash) {
                        this.anchorValue = anchor.label
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    /deep/ strong {
        color: #000;
    }
    .slide-enter,
    .slide-leave-to {
        transform: scaleY(0);
    }
    .add-link {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 16px;
        &__block {
            position: absolute;
            top: 50px;
            left: 16px;
            right: 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: #ffffff;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
            padding: 12px 16px;
            max-height: 116px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        &__item {
            margin-bottom: 16px;
            padding: 0;
            background: transparent;
            &:hover,
            &:active {
                background: transparent;
                opacity: 0.7;
            }
            &:last-child {
                margin: 0;
            }
            &-title {
                color: #9d9d9e;
                font-size: 15px;
                line-height: 20px;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
        }
        &__input {
        }
        &__button {
            margin-top: 16px;
        }
    }
</style>
<style>
    .v--modal-overlay {
        background: none;
    }
    .v--modal-box {
        overflow: inherit !important;
    }
</style>
